<template>
  <van-nav-bar
    :title="title"
    left-text="返回"
    left-arrow
    @click-left="onBackClick"
  />
</template>

<script>
export default {
  name: 'index',
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  methods: {
    onBackClick () {
      this.$router.back()
    }
  }
}
</script>
