import request from './request'
const wechatAPI = 'wechat'

const url = {
  scanAddUser: `${wechatAPI}/wechat/h5AddUser`, // 扫描签约
  mineInfo: `${wechatAPI}/wechat/getH5MineInfo`, // 我的信息
  addMessageVO: 'message/messageInfo/addMessageVO', // 短信验证
  initLive: `${wechatAPI}/wechat/initLive`, // 个人活体注册认证初始化
  submitVedio: `${wechatAPI}/wechat/submitH5Vedio` // 个人活体提交视频
}

const requestHTAPI = {
  scanAddUser: params => {
    return request.postJson(url.scanAddUser, params)
  },
  mineInfo: params => {
    return request.postJson(url.mineInfo, params)
  },
  addMessageVO: params => {
    return request.postJson(url.addMessageVO, params)
  },
  initLive: params => {
    return request.postJson(url.initLive, params)
  },
  submitVedio: params => {
    return request.postJson(url.submitVedio, params)
  }
}

export default requestHTAPI
