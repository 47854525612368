import axios from 'axios'

// 请求超时时间，300s
const requestTimeOut = 300 * 1000

// 系统全局请求对象
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: requestTimeOut,
  responseType: 'json'
})

service.interceptors.request.use(
  config => {
    const _config = config
    _config.headers.token = new Date().getTime()
    return _config
  },
  error => {
    console.log(error)
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use((config) => {
  if (config.data.code === '0002') {
    localStorage.clear()
  }
  return config
}, (error) => {
  return Promise.reject(error)
})

const request = {
  post (url, params) {
    return service.post(url, params, {
      transformRequest: [(params) => {
        return tansParams(params)
      }],
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
  },
  get (url, params) {
    let _params
    if (Object.is(params, undefined)) {
      _params = ''
    } else {
      _params = '?'
      for (const key in params) {
        if (params.hasOwnProperty(key) && params[key] !== null) {
          _params += `${key}=${params[key]}&`
        }
      }
    }
    return service.get(`${url}${_params}`)
  },
  postJson (url, params) {
    return service.post(url, params, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
  }
}

const tansParams = (params) => {
  let result = ''
  Object.keys(params).forEach((key) => {
    if (!Object.is(params[key], undefined) && !Object.is(params[key], null)) {
      result += encodeURIComponent(key) + '=' + encodeURIComponent(params[key]) + '&'
    }
  })
  return result
}

export default request
